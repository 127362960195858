<template>
  <div>
    <v-row dense>
      <v-col cols="4">
        <select-pos-model
          v-model="id_pos"
          :label="$t('labels.pos')"
          dense
          outlined
          clearable
          hide-details
          @change="onPosUpdate"
        ></select-pos-model>
      </v-col>
      <v-col cols="8">
        <input-qr-scan-model
          v-model="code"
          ref="codeRef"
          :label="placeholder"
          @keyupEnter="inputScan"
          :auto-close="false"
        ></input-qr-scan-model>
      </v-col>
    </v-row>

    <div class="mt-3">
      <v-alert color="error" text dark class="text-center mb-0">
        <span class="fs-16">{{ $t("labels.current_position") }}: </span>
        <span class="text-h5">{{ location_cell_id }}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "OldTransfer",
  components: {},
  props: {},
  data: () => ({
    isLoading: false,
    code: null,
    id_pos: null,
    location_cell_id: null,
    receiptItem: {},
    uids: [],
    dialogScanMultiple: false,
  }),
  computed: {
    step() {
      if (!this.location_cell_id) {
        return 1;
      } else if (!this.receiptItem || !this.receiptItem.id) {
        return 2;
      } else {
        return 3;
      }
    },
    placeholder() {
      switch (this.step) {
        case 1:
          return this.$t("labels.location_code");
        case 2:
          return this.$t("labels.uid");
        case 3:
          return this.$t("labels.uid_or_position");
        default:
          return "--- ERROR ---";
      }
    },
  },
  mounted() {},
  methods: {
    onPosUpdate() {
      if (!this.id_pos) {
        return false;
      }
      this.$nextTick(() => {
        this.$refs.codeRef.focusInput();
      });
    },
    restartAll() {
      this.isLoading = false;
      this.code = null;
      // this.location_cell_id = null
      this.receiptItem = {};
      this.uids = [];
    },
    showDialogScanMultiple() {
      this.dialogScanMultiple = true;
    },
    hideDialogScanMultiple() {
      this.dialogScanMultiple = false;
    },
    async inputScan() {
      if (!this.code) {
        return false;
      }

      if (!this.location_cell_id) {
        await this.scanBasket();
      } else {
        if (this.code.includes("@")) {
          await this.confirmBasketUid();
        } else {
          await this.scanBasket();
        }
      }

      /* switch (this.step) {
        case 1:
          await this.scanBasket()
          break;
        case 2:
          await this.scanUid()
          break;
        case 3:
          if (this.code == this.location_cell_id) {
            await this.confirmBasketUid()
          } else {
            await this.scanUid()
          }
          break;
        default:
          this.$vToastify.error(' --- ERROR --- ')
          this.$root.$emit('playErrorAudio')
          break;
      } */
    },
    async scanUid() {
      if (this.uids.includes(this.code)) {
        this.code = null;
        this.$root.$emit("playErrorAudio");
        this.$vToastify.error(this.$t("messages.uid_scanned"));
        return false;
      }

      /* if (this.uids.length > 0) {
        const uid = this.uids[0]
        const [oSku] = uid.split('@')
        if (this.code.includes('@')) {
          const [nSku] = this.code.split('@')
          if (oSku !== nSku) {
            this.code = null
            this.$vToastify.warning(this.$t('messages.wrong_goods'));
            return false
          }
        }
      } */

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/receipt-get-goods-old-transfer",
          {
            code: this.code,
            id_pos: this.id_pos,
          }
        );
        /* if (this.receiptItem && this.receiptItem.sku && this.receiptItem.sku != data.sku) {
          this.$root.$emit('playErrorAudio')
          this.code = null
          this.$vToastify.warning(this.$t('messages.wrong_goods'));
          return false
        } */
        this.uids.push(data.sku_uid);
        this.receiptItem = {
          ...data,
          receipted_quantity: this.uids.length,
        };

        // this.$root.$emit('playSuccessAudio')
        this.code = null;
        this.isLoading = false;
        this.confirmBasketUid();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.$root.$emit("playErrorAudio");
        this.code = null;
        this.isLoading = false;
      }
    },
    async scanBasket() {
      try {
        const { data } = await httpClient.post(
          "/receipt-get-basket-or-location",
          {
            code: this.code,
            id_pos: this.id_pos,
            id_goods: 0,
          }
        );
        this.code = null;

        if (!data.location) {
          this.$vToastify.error(this.$t("messages.wrong_location_code"));
          this.$root.$emit("playErrorAudio");
          return false;
        }
        this.location_cell_id = data.location.cell_id;
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        console.error(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.$root.$emit("playErrorAudio");
      }
    },
    async confirmBasketUid() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post(
          "/receipt-check-basket-and-create-history-old-transfer",
          {
            location_cell_id: this.location_cell_id,
            id_pos: this.id_pos,
            uids: [this.code],
          }
        );

        this.$root.$emit("playSuccessAudio");
        this.restartAll();
      } catch (e) {
        console.error(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
  },
};
</script>

<style scoped></style>
